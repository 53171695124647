body,
html {
  height: 100%;
}

.va-title {
  font-size: 20px;
}

.va-heading1 {
  font-size: 14px;
  font-weight: bold;
}

.va-heading2 {
  font-size: 12px;
}

.va-heading3 {
  font-size: 12px;
}

.va-text {
  font-size: 12px;
}

.va-small-text {
  font-size: 11px;
}

body {
  font-family: 'Arial' !important;
}

.navbar {
  min-height: 35px;
}

.toolbar-element {
  height: 2.0vh;
  min-height: 24px;
  cursor: pointer;
}

.toolbar-element-container {
  padding: 1px;
  margin-left: 4px;
  float: left;
}

#cy {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100%;
  border: 2px solid #2F4154;
  border-radius: 6px;
  background: #ffffff;
}

app-navbar {
  grid-area: nb;
}

app-toolbar {
  grid-area: tb;
  padding-left: 10px;
}

app-cytoscape {
  grid-area: cy;
  position: relative;
}

app-timebar {
  grid-area: ti;
  padding-left: 10px;
}

app-operation-tabs {
  grid-area: ot;
  padding-left: 10px;
  overflow-y: scroll;
}

.root {
  display: grid;
  grid-template-areas: 'nb nb''tb tb''cy ot''ti ti';
  height: 99vh;
  grid-template-columns: 75% 25%;
  grid-template-rows: min-content min-content auto max-content;
}

.zoom-cursor {
  cursor: url(../src/assets/img/zoom-cursor.svg), pointer !important;
}

.filter-node-class {
  margin: 1%;
}

.filter-edge-class {
  color: #343a40;
  border-bottom: 2px solid #343a40;
  margin: 1%;
  padding: 3px;
  text-decoration: none;
}

.filter-edge-class:hover {
  background-color: #343a40;
  color: white;
  border-radius: 3px;
  text-decoration: none;
}

.filter-class-disabled {
  opacity: 0.4;
}

.filter-form {
  padding: 3px 20px 3px 5px;
}

.filter-input {
  padding-right: 5px;
}

#rule-elements {
  font-size: 0.875rem;
}

#filter-add-rule-btn {
  vertical-align: sub;
}

.logic-op-link {
  display: inline-block;
  border: 2px solid;
  border-radius: 5px;
  color: inherit;
  width: 3em;
}

.panel-heading {
  cursor: pointer;
  text-align: center;
  background-color: #eaeaea;
  line-height: 1.45;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.subpanel-heading {
  cursor: pointer;
  text-align: center;
  background-color: #eaeaea40;
  line-height: 1.45;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.panel-heading-hideable {
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #eaeaea;
}

.panel-title {
  font-size: 12px;
  color: #555555;
  vertical-align: middle;
  display: table-cell;
}

.panel-body {
  padding-top: 7px;
  padding-bottom: 3px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.panel {
  border: 2px solid transparent;
}

/* CSS Method for adding Font Awesome Chevron Icons  symbol for "opening" panels*/

.accordion-toggle:after {
  font-family: 'FontAwesome', sans-serif;
  content: "\f077";
  float: right;
  padding-right: 4px;
  color: inherit;
}

/* symbol for "collapsed" panels */

.panel-heading.collapsed .accordion-toggle:after {
  content: "\f078";
}

/* symbol for "collapsed" panels */

.subpanel-heading.collapsed .accordion-toggle:after {
  content: "\f078";
}

/* borders for navigator and map */

.cytoscape-navigator-wrapper {
  position: fixed;
  width: 300px;
  height: 200px;
  overflow: hidden;
  border: 2px solid #2F4154;
  border-radius: 6px;
  background: #fff;
}

[data-bs-toggle="collapse"] .fa:before {
  content: "\f139";
}

[data-bs-toggle="collapse"].collapsed .fa:before {
  content: "\f13a";
}

#highlight-search-inp {
  width: 75px;
  border-radius: 5px;
  height: 23px;
}

/* custom css for context-menu extension */

.vall-ctx-menu-item:hover {
  color: #16181b !important;
  background-color: #f8f9fa !important;
}

.vall-ctx-menu-item {
  background-color: #ffffff !important;
  padding: 0.25rem 1.5rem !important;
  width: 100% !important;
  font-weight: 400 !important;
  color: #212529 !important;
}

.vall-ctx-menu {
  padding: .5rem 0 !important;
  margin: .125rem 0 0 !important;
  border: 1px solid rgba(0, 0, 0, .15) !important;
  background-color: #ffffff !important;
  border-radius: .25rem !important;
}

.modal-title {
  margin: 0 auto;
}

.modal-header {
  padding: 0.5rem 1rem;
}

.img-icon {
  width: 20px;
  height: 20px;
}

.img-sm-icon {
  width: 12px;
  height: 12px;
}

.query-input {
  width: 60px;
  display: inline;
  margin-top: 2px;
}

.query-input-wide {
  width: 105px;
  display: inline;
  margin-top: 2px;
}

.custom-query {
  padding: 2%;
  color: black;
}

.cb-filter {
  margin-left: -1rem !important;
}

.strokeme {
  color: white;
  text-shadow: 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black, 0px -1px 1px black;
}

.tight-select {
  background-position: center right 5px;
  height: inherit;
}

.query-select {
  text-align: center;
  text-align-last: center;
  width: auto;
}

.inp-3-char {
  width: 5.5em;
}

.pointer {
  cursor: pointer;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.std-inp-hei {
  height: 2em;
  padding: .25rem !important;
}

.std-inp-hei-sec {
  height: 2.25em;
}

.form-inline {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

label.form-check-label,
input.form-check-input {
  cursor: pointer;
}

/* custom selected bar charts in Timebar */

rect[fill-opacity="0"]:not([stroke="none"]) {
  stroke: white;
  stroke-width: 2px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Data Configuration Model */

.mat-dialog-container {
  overflow: hidden !important;
  width: 1000px !important;
  height: 520px !important;
  padding: 0 !important;
  margin-left: calc((80vw - 1000px)/2);
  margin-right: calc((80vw - 1000px)/2);
  margin-top: calc((80vh - 500px)/2);
  margin-bottom: calc((80vh - 500px)/2);
}

/* Angular Material UI Auto Complete Component Changed Style*/

.mat-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 28px !important;
  height: 28px !important;
  width: max-content !important;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  max-width: 150px !important;
}

.mat-option.large {
  max-width: 20vw !important;
}

.mat-autocomplete-panel {
  scrollbar-width: none !important;
  min-width: max-content !important;
  border: 1px solid #ccc !important;
  background-color: #ececec !important;
  border-radius: 5px !important;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover)::before {
  content: '✓';
  /* Unicode checkmark character */
  display: inline-block;
  position: absolute;
  left: 5px;
  /* Adjust the left position of the checkmark */
  top: 50%;
  transform: translateY(-50%);
  color: black;
  background: #ececec !important;
  /* Adjust the color of the checkmark */
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #ececec !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgb(0 0 0 / 4%);
  border-radius: 5px !important;
}